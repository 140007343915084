<template>
  <div class="content" v-loading="loadingStatus.getTable">
    <div class="con-tab">
      <div>温馨提示</div>
      <div>
        亲爱的合作伙伴，请务必使用后台提取的下载地址游戏，请勿直接传输apk文件包给用户，否则有小概率跳包情况出现，感谢大家的支持
      </div>
    </div>
    <div>
      <el-form
        ref="staffRef"
        size="medium"
        :model="dataForm"
        label-width="90px"
      >
        <el-row>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="所属工会：">
              <el-select style="width: 100%;" v-model="dataForm.presidentId" placeholder="请选择" clearable filterable @change="presidentIdChange">
                <el-option v-for="(item ) in presidentList" :key="item.id"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="小组：">
              <el-select style="width: 100%" v-model="dataForm.groupId" placeholder="请选择" clearable @change="groupIdChange">
                <el-option v-for="(item ) in groupList" :key="item.id"
                           v-show="item.presidentId === dataForm.presidentId || !dataForm.presidentId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="员工：">
              <el-select style="width: 100%" v-model="dataForm.stuffId" placeholder="请选择" clearable>
                <el-option v-for="(item) in empList" :key="item.id"
                           v-show="item.groupId=== dataForm.groupId || !dataForm.groupId"
                           :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
            <el-form-item label="游戏：">
              <el-select
                style="width: 100%;"
                v-model="dataForm.gameId"
                clearable
              >
                <el-option
                  v-for="item in gamesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <div
            style="display: inline-block;padding-left: 10px;padding-bottom: 20px"
          >
            <el-button type="primary" size="medium" @click="getTable"
              >查询</el-button
            >
            <el-button plain size="medium" @click="getReset">重置</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
    <div>
      <el-table :data="tableData" border :stripe="true" style="width: 100%">
        <el-table-column
          prop="gameName"
          width="140"
          label="游戏名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="gameVersion"
          label="版本"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="presidentName"
          width="140"
          label="所属公会"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="stuffName"
          width="180"
          label="推广员"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="downloadUrl"
          width="180"
          label="下载地址"
          align="center"
        >
          <template slot-scope="scope">
            <div  class="copyBtn">
              {{ scope.row.downloadUrl }}
            </div>
          </template>
        </el-table-column>

        <el-table-column width="180" label="复制下载地址" align="center">
          <template slot-scope="scope">
            <button
              class="copyBtn"
              data-clipboard-action="copy"
              id="copyTrigger"
              :data-clipboard-text="scope.row.downloadUrl"
              @click="getCopy"
            >
              点击复制
            </button>
          </template>
        </el-table-column>
        <el-table-column
          prop="openDatetime"
          width="160px"
          label="开通时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lastReleaseDatetime"
          width="160px"
          label="最后打包时间"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="lastReleaseStatus"
          label="打包状态"
          align="center"
        >
          <template slot-scope="scope">
            <span style="color: #1D90FF" v-if="scope.row.lastReleaseStatus == 1"
              >已打包</span
            >
            <span style="color: red" v-else>未打包</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="lastUpdatedStatus"
          label="升级状态"
          align="center"
        >
          <template slot-scope="scope">
            <span style="color: #1D90FF" v-if="scope.row.lastUpdatedStatus == 1"
              >已升级</span
            >
            <span style="color: red" v-else>未升级</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        class="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="1"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
        :current-page="page.pageNo"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Clipboard from 'clipboard'

import loadingStatusMixin from '@/mixins/loadingStatus';
import {
  commonInfo,
  promotionEmpPromotionList,
} from '../../service/moudules/promotionLinkApi';
export default {
  name: 'staffPromotion',
  mixins: [loadingStatusMixin],

  data() {
    return {
      dataForm: {
        groupId: '', // 组ID
        stuffId: '', // 员工ID
        gameId: '', // 游戏ID
        presidentId: '',
      },
      tableData: [],
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      groupList: [], //小组
      empList: [], //员工信息
      gamesList: [], //游戏
      presidentList: [],
      notShowUrl: false,
    };
  },
  mounted() {
    this.getTable();
    this.getCommonInfo();
  },
  methods: {
    presidentIdChange(value) {
        this.dataForm.groupId = ''
        this.dataForm.stuffId = ''
    },
    groupIdChange(value) {
        this.dataForm.stuffId = ''
    },
    //点击复制信息
    getCopy() {
      //使用main.js内注册的vue原型，用class绑定将数据存储到clipboard中
      let clipboard = new Clipboard('.copyBtn')
      clipboard.on('success', (e) => {
        this.$message({
          message: '复制成功！',
          type: 'success',
        });
        //清除选中
        e.clearSelection();
        //释放内存，以防重复复制
        clipboard.destroy();
      });
      clipboard.on('error', () => {
        this.$message.error('复制失败，请手动选择复制！');
      });
    },

    async getCommonInfo() {
      let res = await commonInfo();
      if (res.code == 200) {
        this.groupList = res.data.groupList;
        this.empList = res.data.empList;
        this.gamesList = res.data.gamesList;
        this.presidentList = res.data.presidentList;
      }
    },
    async getTable() {
      if (this.hasLoadingState('getTable')) return;
      try {
        this.addLoadingState('getTable');
        let params = {
          pageNo: this.page.pageNo,
          pageSize: this.page.pageSize,
        };
        const searchFormValue = this.dataForm;
        Object.keys(searchFormValue).map((key) => {
          searchFormValue[key] && (params[key] = searchFormValue[key]);
        });
        let res = await promotionEmpPromotionList(params);
        this.removeLoadingState('getTable');
        if (res.code == 200) {
          this.tableData = res.data.records;
          // this.page.pageNo = res.data.pages;
          this.page.pageNo = res.data.current;
          this.page.total = res.data.total;
        } else {
          this.removeLoadingState('getTable');
          this.$message.error(`${res.msg}！`);
        }
      } catch (e) {
        this.removeLoadingState('getTable');
      }
    },
    getReset() {
      this.dataForm = {
        groupId: '', // 组ID
        stuffId: '', // 员工ID
        gameId: '', // 游戏ID
        presidentId: '',
      };
      this.getTable();
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.page.pageNo = 1;
      this.getTable();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTable();
    },
  },
};
</script>

<style scoped lang="less">
.content {
  padding: 20px;
  margin: 10px;
  border: 1px solid #dcdfe6;
  text-align: initial;
  background-color: white;
  .con-tab {
    background-color: #e6a23c2b;
    padding: 10px 8px;
    border-radius: 8px;
    font-size: 14px;
    color: #e6a23c;
    margin-bottom: 20px;
  }
  .page {
    text-align: right;
    margin-top: 15px;
  }
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar {
    width: 1px;
    height: 10px;
  }
  ::v-deep
    .el-table--scrollable-x
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
  ::v-deep
    .el-table--scrollable-x
    .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.1);
  }
  ::v-deep .el-table__header th.el-table__cell {
    height: 50px;
    background-color: #fafafa;
    color: #333333;
    padding: 0;
  }
}
</style>
